import { TIME_SCALE } from '@/config/enum';
import { FileType } from './enum';

export const findMax = (data: number[]) => {
  console.log('data received:', data);
  let max = data[0];

  data?.forEach((dataPoint) => {
    if (dataPoint > max) max = dataPoint;
  });

  return max + 50;
};

export const findMin = (data: number[]) => {
  let min = data[0];

  data?.forEach((dataPoint) => {
    if (dataPoint < min) min = dataPoint;
  });

  return min - 50 < 0 ? 0 : min - 50;
};

export const formatNumberToFiveDigits = (number: number) => {
  let numStr = number.toString();
  let [integerPart, decimalPart] = numStr.split('.');

  if (integerPart.length + decimalPart.length > 5) {
    return parseFloat(numStr.substring(0, 5));
  }

  if (!decimalPart) {
    decimalPart = '';
  }

  let combined = integerPart + decimalPart;

  while (combined.length < 5) {
    combined += '0';
  }

  integerPart = combined.substring(0, integerPart.length);
  decimalPart = combined.substring(integerPart.length);

  let result = integerPart + '.' + decimalPart;

  return result;
};

export const options = [
  {
    label: 'Last 30 days',
    value: TIME_SCALE.LAST_30_DAYS
  },
  {
    label: 'Last week',
    value: TIME_SCALE.LAST_WEEK
  },
  {
    label: 'Last 6 months',
    value: TIME_SCALE.LAST_6_MONTHS
  },
  {
    label: 'Last year',
    value: TIME_SCALE.LAST_1_YEAR
  },
  {
    label: 'Custom',
    value: TIME_SCALE.CUSTOM
  }
];

export const getUnixFromTimeScale = (
  end: moment.Moment,
  selectedTimeScale: TIME_SCALE
) => {
  const to = end;
  switch (selectedTimeScale) {
    case TIME_SCALE.LAST_WEEK:
      to.subtract(1, 'week');
      break;
    case TIME_SCALE.LAST_30_DAYS:
      to.subtract(1, 'month');
      break;
    case TIME_SCALE.LAST_6_MONTHS:
      to.subtract(6, 'months');
      break;
    case TIME_SCALE.LAST_1_YEAR:
      to.subtract(1, 'year');
      break;
    default:
      break;
  }

  return to;
};

export const getWeekendAreas = (labels: string[]) => {};

export const isValidFileType = (
  type: string,
  validFileTypes: FileType[]
): boolean => validFileTypes.includes(type as FileType)