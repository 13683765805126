import { UpdateDeviceDataForm } from "../../../interfaces/form"
import { useEffect, useState } from 'react';
import Space from "../../Space";
import { INPUT_CONTROL_STATE, TYPE_SPACE } from "../../../config/enum";
import Title from "../../FormInput/Title";
import InputText from "../../FormInput/InputText";
import InputSelect, { IOption } from "../../FormInput/InputSelect";
import { ButtonPrimary } from '../../FormInput/Button';
import { DeviceData } from "../../../interfaces/entities"
import { ResponseGeneric } from '../../../interfaces/base-service';
import ElectripureService from '../../../service/electripure-service';
import { validateSerialControl, validateRequiredControl, validateNumberControl} from "../../../libs/form-validation"
import { InputControl } from '../../../interfaces/form-control';
import { IGetGMTResponse } from "@/interfaces/electripure-service";
import InputSelect2 from "@/components/FormInput/InputSelect2";


const DeviceUpdateForm = ({device, onSubmit}:{device: DeviceData, onSubmit: (data: UpdateDeviceDataForm) => void}) =>{
    
    const [ deviceSetControl, setDeviceControl ] = useState({
        "state": INPUT_CONTROL_STATE.DEFAULT,
        "value": "", 
        "message": ""
    })

    const [ serialControl, setSerialControl ] = useState({
        "state": INPUT_CONTROL_STATE.DEFAULT,
        "value": device.serial_number.toString(), 
        "message": ""
    })

    const [ mdpNameControl, setMdpNameControl ] = useState({
        "state": INPUT_CONTROL_STATE.OK,
        "value": device.MDP_name, 
        "message": ""
    })

    const [ switchgearControl, setSwitchgearControl ] = useState({
        "state": INPUT_CONTROL_STATE.OK,
        "value": device.switchgear, 
        "message": ""
    })

    const [ transformerControl, setTransformerControl ] = useState({
        "state": INPUT_CONTROL_STATE.OK,
        "value": device.transformer, 
        "message": ""
    })

    const [ timezoneControl, setTimezoneControl ] = useState({
        "state": INPUT_CONTROL_STATE.OK,
        "value": device.timezone,
        "message": ""
    })

      // SECTION Get GMTs
    const [gmtOptions, setGmtOptions] = useState<IOption[]>([]);
    useEffect(() => {
        (async () => {
            const response: ResponseGeneric = await ElectripureService.listGMTs();
            const gmtOptions: IOption[] = (
                response.data as IGetGMTResponse
            ).GMT_ID.map((gmtId, index) => ({
                id: `${gmtId}`,
                value: (response.data as IGetGMTResponse).GMT_Name[index]
            }));
            setGmtOptions(gmtOptions);
        })();
    }, []);

    function submit(){
        if(serialControl.state == INPUT_CONTROL_STATE.OK &&
            deviceSetControl.state == INPUT_CONTROL_STATE.OK &&
            mdpNameControl.state == INPUT_CONTROL_STATE.OK &&
            switchgearControl.state == INPUT_CONTROL_STATE.OK &&
            transformerControl.state == INPUT_CONTROL_STATE.OK){
            onSubmit({
                device_id: device.id_device,
                meterID: deviceSetControl.value === "meter"? serialControl.value.toString() : "0",
                applianceID: deviceSetControl.value === "appliance"? serialControl.value.toString() : "0",
                device_name: mdpNameControl.value,
                switch_gear: switchgearControl.value,
                transformer: transformerControl.value,
                timezone: timezoneControl.value
            });
       } else {
            setSerialControl(validateRequiredControl(serialControl));
            setMdpNameControl(validateRequiredControl(mdpNameControl));
       }
    };

    return (
        <div className="w-full bg-color-white p-[10px]">
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <div className="mx-auto w-full max-w-[650px]" style={{ "textAlign": "center" }}>
            <Title title="Update Device information"/>
        </div>
        <div>
           <h3 className="color-primary-dark f-bold subtitle">{"Device"}</h3>
           <Space type={TYPE_SPACE.INPUT_DISTANCE} />
           <InputSelect 
               name={"Select device type"}
               placeholder="Select"
               defaultSelect={device.type_device === "Meter"? "meter": "appliance"}
               state={deviceSetControl.state}
               options={[{'id': 'meter', 'value': "Meter ID"}, {'id': 'appliance', 'value': "Appliance ID"}]}
               label="Select device type"
               onChange={(selected: {"value": any, "id":any})=>{
                   setDeviceControl({
                       value: selected.id,
                       message: "",
                       state: INPUT_CONTROL_STATE.OK
                   })
               }}
               message={deviceSetControl.message}
           />
           <Space type={TYPE_SPACE.INPUT_DISTANCE} />
           <InputText
               name={"name"}
               placeholder="Device Serial"
               label="Device Serial"
               defaultValue={serialControl.value.toString()}
               onChange={(value: string)=> {
                   const newSerialControl: InputControl = validateSerialControl(value)
                   setSerialControl(newSerialControl);
               }}
               state={serialControl.state}
               message={serialControl.message}
           />
           <Space type={TYPE_SPACE.INPUT_DISTANCE} />
           <InputText
               name={"mdp_name"}
               placeholder="MDP Name"
               label="MDP Name"
               value={mdpNameControl.value}
               onChange={(value: string)=> {
                let input: InputControl = {
                    value: value,
                    message: '',
                    state: INPUT_CONTROL_STATE.OK
                };
                setMdpNameControl(validateRequiredControl(input));
               }}
               state={mdpNameControl.state}
               message={mdpNameControl.message}
           />
           <Space type={TYPE_SPACE.INPUT_DISTANCE} />
           <InputText
               name={"switchgear"}
               placeholder="Rating"
               label="Rating"
               value={switchgearControl.value}
               onChange={(value: string)=> {
                const newSwitchgearControl: InputControl = validateNumberControl(value)
                setSwitchgearControl(newSwitchgearControl);
               }}
               state={switchgearControl.state}
               message={switchgearControl.message}
           />
           <Space type={TYPE_SPACE.INPUT_DISTANCE} />
           <InputText
               name={"transformer"}
               placeholder="Transformer"
               label="Transformer"
               value={transformerControl.value}
               onChange={(value: string)=> {
                const newTransformerControl: InputControl = validateNumberControl(value)
                setTransformerControl(newTransformerControl);
               }}
               state={transformerControl.state}
               message={transformerControl.message}
           />
            <Space type={TYPE_SPACE.INPUT_DISTANCE} />
            <InputSelect2
                name="timezone"
                label="Timezone"
                options={gmtOptions}
                placeholder="Select timezone"
                state={timezoneControl.state}
                value={timezoneControl.value}
                message={timezoneControl.message}
                onChange={(select: { value: any; id: any }) => {
                    setTimezoneControl( {
                        state: INPUT_CONTROL_STATE.OK,
                        message: '',
                        value: select.id
                    });
                }}
            />

           <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        </div>
        <Space classes="w-full h-[50px]" />
        <div className="w-full max-w-[400px] mx-auto flex">
            <Space type={TYPE_SPACE.INPUT_DISTANCE_VERTICAL} />
            <ButtonPrimary onClick={submit}>
                Update
            </ButtonPrimary>
        </div>
    </div>
    )
}

export default DeviceUpdateForm;
