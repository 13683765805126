import { STATUS } from '@/config/enum';

type MeterInformationProps = {
  status: STATUS;
  meterId: number;
};

export const MeterInformation = ({
  status,
  meterId
}: MeterInformationProps) => {
  const capitalizedWordStatus =
    status?.slice(0, 1).toUpperCase() + status?.slice(1);
  return (
    <div className="rounded-md border-gray-200-electripure border-2 w px-4 py-2 bg-white">
      <p className="text-xl font-medium mb-2">Meter Information</p>
      <div className="flex justify-between text-gray-400-electripure grow">
        <span className="w-full">Meter Status</span>
        <span
          className={`w-full font-bold text-black self-center ${
            status === STATUS.OFFLINE ? 'text-red-500' : 'text-green-500'
          }`}>
          {capitalizedWordStatus || ''}
        </span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Meter ID</span>
        <span className="w-full font-bold text-black">{meterId}</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Firmware Version</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Hardware Version</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Serial Number</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Probe Type</span>
      </div>
    </div>
  );
};
