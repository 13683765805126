import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

function MeterPage() {
  const { meterId } = useParams();

  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    setInterval(() => {
      setLoading(true);
    }, 500);
  }, [meterId]);

  return loading && <Outlet />;
}

export default MeterPage;
