import { useState } from 'react';
import InputCheckboxIcon from '../../FormInput/InputCheckboxIcon';
import AverageControl from '../../AverageControl';

const iconNameDict: any = {
  'Power Units': (isActive: boolean) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        className={`w-8 h-8 ${isActive ? 'fill-blue-500' : ''}`}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
        />
      </svg>
    );
  },
  Display: (isActive: boolean) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`w-8 h-8 ${isActive ? 'stroke-blue-500' : ''}`}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
        />
      </svg>
    );
  },
  Channel: (isActive: boolean) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`w-8 h-8 ${isActive ? 'stroke-blue-500' : ''}`}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
        />
      </svg>
    );
  }
};

export interface yDataControl {
  name: string;
  element: any;
}

function generateInitState(yDataControlList: yDataControl[]): any {
  const state: any = {
    Channel: false
  };
  yDataControlList.forEach((yDataControl: yDataControl) => {
    state[yDataControl.name] = false;
  });
  return state;
}

function GroupMenu({
  channelControl,
  yDataControlList
}: {
  channelControl: any;
  yDataControlList: yDataControl[];
}) {
  const [rawActiveDict, setRawActiveDict] = useState(
    JSON.stringify(generateInitState(yDataControlList))
  );
  const activeDict: any = JSON.parse(rawActiveDict);
  return (
    <div className="flex justify-center items-center">
      <InputCheckboxIcon
        disabled={false}
        defaultChecked={activeDict['Channel']}
        classes={`f-semibold rounded-l-md relative ${
          yDataControlList.length == 0 ? 'rounded-r-md ' : ''
        }`}
        name={'Channel'}
        tooltip="Channels"
        onChange={(checked: boolean) => {
          Object.keys(activeDict).forEach((key: string) => {
            activeDict[key] = false;
          });
          activeDict['Channel'] = checked;
          setRawActiveDict(JSON.stringify(activeDict));
        }}>
        {iconNameDict['Channel'](activeDict['Channel'])}
        {activeDict['Channel'] ? (
          <div className="absolute bottom-0 right-0 z-50 bg-white rounded-sm border border-color-black-light border-1 translate-y-[100%] p-4">
            {channelControl}
          </div>
        ) : (
          ''
        )}
      </InputCheckboxIcon>
      {yDataControlList.map((yDataControl: yDataControl, index: number) => {
        return (
          <InputCheckboxIcon
            disabled={false}
            defaultChecked={activeDict[yDataControl.name]}
            classes={`f-semibold relative ${
              index == yDataControlList.length - 1 ? 'rounded-r-md ' : ''
            }`}
            name={yDataControl.name}
            tooltip={yDataControl.name}
            onChange={(checked: boolean) => {
              Object.keys(activeDict).forEach((key: string) => {
                activeDict[key] = false;
              });
              activeDict[yDataControl.name] = checked;
              setRawActiveDict(JSON.stringify(activeDict));
            }}>
            {iconNameDict[yDataControl.name](activeDict[yDataControl.name])}
            {activeDict[yDataControl.name] && (
              <div className="absolute bottom-0 right-0 bg-white z-50	rounded-sm border border-color-black-light border-1 translate-y-[100%] p-4">
                {yDataControl.element}
                <AverageControl />
              </div>
            )}
          </InputCheckboxIcon>
        );
      })}
    </div>
  );
}

export default GroupMenu;
