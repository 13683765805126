import { ReactNode } from 'react';

type EntityFormProps = {
  title: string;
  children: ReactNode;
};

export const EntityForm = ({ title, children}: EntityFormProps) => {
  return (
    <>
      <p className="text-center text-blue-300-electripure text-2xl font-semibold mb-8">
        {title}
      </p>
      {children}
    </>
  );
};
