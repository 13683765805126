import { jwtDecoded } from '../interfaces/actions';
import jwt_decode from 'jwt-decode';

export function settingPermissions(role: string) {
  const localJwt = localStorage.getItem('electripureJwt');
  if (localJwt === null || localJwt === undefined || localJwt === '') {
    return [undefined, undefined];
  }
  const jwt: jwtDecoded = jwt_decode(localJwt);

  const userId = jwt?.data;
  const roleUser = jwt?.role;

  return [roleUser[role], userId];
}
