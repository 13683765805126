import { DropdownMultiple } from '@/common/Input/Dropdown/Multiple';
import { CompanyEntity } from '@/interfaces/entities';
import { ElectripureState } from '@/interfaces/reducers';
import { FieldArray, Form, Formik, FormikValues } from 'formik';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityForm } from '../EntityForm';
import { TextInput } from '@/common/Input/Text';
import { RadioButton } from '@/common/Input/Radio';
import { CreateUserFormData } from '@/utils/types';
import { Button } from '@/common/Button';
import { CreateUserSchema } from '@/schemas';
import { ROLES } from '@/utils/roles';
import { EmergencyContactForm } from './EmergencyContactForm';
import { SecondaryContactForm } from './SecondaryContactForm';
import { ObjectRequestMapper } from '@/mappers/request.mapper';
import ElectripureService from '@/service/electripure-service';
import { toast } from 'react-toastify';
import { sendGetUsers } from '@/actions/electripure';

type CreateUserFormProps = {
  toggleModal: () => void;
};

export const CreateUserForm = ({ toggleModal }: CreateUserFormProps) => {
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);

  const companies: CompanyEntity[] = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  );

  const dispatch = useDispatch();

  const companyDropdownOptions = useMemo(
    () =>
      companies.map((company) => ({
        label: company.company_name,
        value: company.company_id
      })),
    [companies]
  );

  const siteDropdownOptions = useMemo(
    () =>
      companies
        .filter((company) => selectedCompanies.includes(company.company_id))
        .map((company) => ({
          label: company.company_name,
          options: company.list_sites.map((site) => ({
            label: site.name,
            value: site.id
          }))
        })),
    [selectedCompanies, companies]
  );

  const roleRadioOptions = Object.entries(ROLES).map(([key, value]) => {
    const capitalizedKey = key.split('_').map((str) => {
      return `${str[0]}${str.slice(1).toLowerCase()}`;
    });

    return {
      id: key,
      label: capitalizedKey.join(' '),
      value
    };
  });

  const handleCompanyDropdownChange = (selectedOptions) => {
    setSelectedCompanies(selectedOptions.map((option) => option.value));
  };

  const handleSubmit = async (values: CreateUserFormData) => {
    const objectMap = {
      fullname: 'fullname',
      email: 'email',
      cellphone: 'cellphone',
      role: 'role_name',
      companyIds: 'companies',
      siteIds: 'sites',
      emergencyContact: 'emergency_contact',
      secondaryContact: 'secondary_contact',
      emergency_contact: {
        fullname: 'fullname',
        cellphone: 'cellphone',
        email: 'email'
      },
      secondary_contact: {
        fullname: 'fullname',
        cellphone: 'cellphone',
        email: 'email'
      }
    };

    const body = ObjectRequestMapper.map<CreateUserFormData>(values, objectMap);

    try {
      await ElectripureService.createUserV2(body);
      toast('Successfully created the user', {
        type: 'success',
        position: 'bottom-left'
      });

      toggleModal();

      dispatch(sendGetUsers({}));
    } catch (error: any) {
      console.error(error);
      toast(`Could not create user: ${error}`, {
        type: 'error',
        position: 'bottom-left'
      });
    }
  };

  return (
    <div className="bg-white z-30 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-8 max-h-screen overflow-y-auto w-[600px] max-w-[600px]">
      <EntityForm title="Let's get some basic information">
        <Formik<CreateUserFormData>
          validationSchema={CreateUserSchema}
          validateOnMount={false}
          validateOnBlur={true}
          validateOnChange={false}
          initialValues={{
            cellphone: '',
            companyIds: [],
            email: '',
            fullname: '',
            role: ROLES.CUSTOMER_ADMIN,
            siteIds: []
          }}
          onSubmit={(values) => handleSubmit(values)}>
          <Form className="flex flex-col grow gap-y-2">
            <TextInput
              name="fullname"
              label="Full Name"
              placeholder="John Doe"
            />
            <div className="flex w-full gap-x-6">
              <TextInput
                name="email"
                label="Email"
                placeholder="email@company.com"
              />
              <TextInput
                name="cellphone"
                label="Cell phone"
                placeholder="(***) *** - ****"
              />
            </div>
            <div className="flex w-full gap-x-6">
              <div className="w-1/2">
                <DropdownMultiple
                  onChange={handleCompanyDropdownChange}
                  options={companyDropdownOptions}
                  label="Company"
                  name="companyIds"
                />
              </div>
              <div className="w-1/2">
                <DropdownMultiple
                  options={siteDropdownOptions}
                  label="Site"
                  name="siteIds"
                  customMenuList={true}
                />
              </div>
            </div>
            <RadioButton name="role" label="Role" options={roleRadioOptions} />
            <EmergencyContactForm />
            <SecondaryContactForm />
            <Button
              type="submit"
              // disabled={isSubmitting}
              className="text-sm mt-4 mx-auto flex jutify-center disabled:bg-blue-50-electripure bg-blue-100-electripure text-white h-10 w-40 rounded-md items-center outline-none">
              <p className="text-center w-full">Add User</p>
            </Button>
          </Form>
        </Formik>
      </EntityForm>
    </div>
  );
};
