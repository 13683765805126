import { useState } from 'react';
import { ICompany, IMdp, ISite } from './interfaces';
import SiteDetail from './SiteDetail';
import MdpSchematic from './components/MdpSchematic';
import Mdps from './Mdps';
import mdpIcon from '@/assets/img/mdp_icon.png';
import { ModalMiddle } from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { sendCreateSite } from '../../actions/electripure';
import SiteForm, { ISiteForm } from '../../components/Form/SiteForm';
import { ElectripureState } from '../../interfaces/reducers';
import { RoleGuard } from '@/guards/RoleGuard';
import { PERMISSIONS } from '@/utils/roles';
import { SiteInfo } from '../../components/SiteInfo';
import { Modal } from '@mui/material';
import { CreateSiteForm } from '@/common/Form/CreateSite';

function Sites() {
  const [showModalCreateSite, setShowModalCreateSite] = useState(false);
  const dispatch = useDispatch();
  const company: any = JSON.parse(
    useSelector((state: ElectripureState) => state.companyDetails)
  );

  const viewMode = useSelector((state: ElectripureState) => state.viewMode);
  company.sites = company.sites ?? [];

  async function submitCreateSite(site: Partial<ISiteForm>) {
    const success = await dispatch(
      sendCreateSite({
        company_id: company.id,
        name: site.name,
        address: site.address,
        address2: site.address2,
        city: site.city,
        state: site.state,
        zip: site.zip,
        payment: site.payment,
        manager_fullname: site.manager_fullname,
        manager_email: site.manager_email,
        manager_phone: site.manager_phone,
        logo: site.logo_id,
        schematic: site.schematic_id
      })
    );

    if (success) setShowModalCreateSite(false);
  }

  return (
    <>
      <div className="flex items-center my-5 gap-x-8">
        <p className="text-2xl whitespace-nowrap inline-block">Site List</p>
        <hr className="w-full" />
        {viewMode === 'mdp' && (
          <RoleGuard allowedPermissions={[PERMISSIONS.CREATE_SITE]}>
            <button
              className="w-[200px] border h-[50px] ml-[10px]"
              onClick={() => setShowModalCreateSite(!showModalCreateSite)}>
              + Add New Site
            </button>
          </RoleGuard>
        )}
      </div>
      <div className="flex flex-col gap-y-4">
        {company.sites.map((site, index: number) => (
          <SiteDetail key={index} site={site} company={company} />
        ))}
      </div>
      <Modal
        open={showModalCreateSite}
        className="relative"
        onClose={() => setShowModalCreateSite(false)}>
        <CreateSiteForm
          companyId={company.id}
          toggleModal={() => setShowModalCreateSite(false)}
        />
      </Modal>
    </>
  );
}

export default Sites;
