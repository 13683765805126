import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import moment, { Moment } from 'moment';
import { Modal } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { Dropdown } from '@/common/Dropdown';
import { IMdp } from '@/pages/CompanyDetailPage/interfaces';
import { TABLE_OPTIONS, TIME_SCALE } from '@/config/enum';
import { DateTime } from '@/common/DateTime';
import ElectripureService from '@/service/electripure-service';
import './style.css';
import { IconCross } from '@/assets/svg';
import { getUnixFromTimeScale, options } from '@/utils/';
import { CustomDates } from '@/types';

type HeaderKeys = keyof TABLE_OPTIONS;

type TableHeaders = {
  key: HeaderKeys;
  label: HeaderKeys;
};

type TableBody = {
  [key in TableHeaders['key']]: string[];
};

type AverageValuesTableProps = {
  mdps: IMdp[];
};

export const AverageValuesTable = ({ mdps }: AverageValuesTableProps) => {
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showCustomInputsModal, setShowCustomInputsModal] =
    useState<boolean>(false);
  const nowRef = useRef<Moment>(moment().utc());
  const now = nowRef.current;
  const [mdpData, setMdpData] = useState<any>();
  const [selectedTimeScale, setSelectedTimeScale] = useState<TIME_SCALE>(
    TIME_SCALE.LAST_30_DAYS
  );
  const [tableOptions, setTableOptions] = useState<TABLE_OPTIONS[]>([
    TABLE_OPTIONS.STATUS,
    TABLE_OPTIONS.kWh,
    TABLE_OPTIONS.PF,
    TABLE_OPTIONS.MIN_C,
    TABLE_OPTIONS.MAX_C,
    TABLE_OPTIONS.MIN_V,
    TABLE_OPTIONS.MAX_V
  ]);
  const [customDates, setCustomDates] = useState<CustomDates>({
    from: now.clone().subtract(1, 'day'),
    to: now
  });

  console.log({ data: Object.entries(mdps), mdpData });

  // TODO: Refactor this variable
  const tableData = useMemo(() => {
    if (!mdpData || !mdps || !Array.isArray(mdps)) {
      const data = tableOptions.reduce((acc, curr) => {
        acc[curr.toString() as keyof HeaderKeys] = Array(mdps.length).fill(' ');
        return acc;
      }, {} as any);

      return data;
    }

    const data = tableOptions.reduce((acc, curr) => {
      acc[curr.toString() as keyof HeaderKeys] = [];
      return acc;
    }, {} as any);

    Object.entries(mdps).map(([, mdp], index) => {
      let d = mdpData[mdp.meter_id];
      if (d) {
        // Si existen datos para ese meterID
        Object.entries(d).forEach(([key, value]) => {
          if (data[key]) {
            // Solo agregar si la clave existe en 'data'
            data[key].push(value);
          }
        });
      }
    });

    return data;
  }, [tableOptions, mdpData, mdps]);

  const handleDateTimeChange = (name: string, value: Moment) =>
    setCustomDates((prev) => ({ ...prev, [name]: value }));

  const doFetch = async (from: Moment, to: Moment, mdpIds: number[]) => {
    const { values } = await ElectripureService.getMdpValues(mdpIds, from, to);

    setMdpData(values);
  };

  const fetchData = useCallback(
    async (customFrom?: Moment, customTo?: Moment) => {
      try {
        const mdpIds = mdps.map((mdp) => mdp.meter_id);

        if (customFrom && customTo) {
          await doFetch(customFrom, customTo, mdpIds);
          return;
        }

        const to = moment().utc();
        const from = getUnixFromTimeScale(to.clone(), selectedTimeScale);

        if (mdps) {
          const { values } = await ElectripureService.getMdpValues(
            mdpIds,
            from,
            to
          );

          setMdpData(values);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [mdps, selectedTimeScale]
  );

  useEffect(() => {
    selectedTimeScale === TIME_SCALE.CUSTOM
      ? setShowButton(true)
      : setShowButton(false);
  }, [selectedTimeScale]);

  useEffect(() => {
    if (selectedTimeScale !== TIME_SCALE.CUSTOM) fetchData();
  }, [fetchData, selectedTimeScale]);

  const handleTimescaleChange = ({
    target
  }: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTimeScale(target.value as TIME_SCALE);
  };

  const handleClickButton = () => {
    // await fetchData(customDates.from, customDates.to);
    setShowCustomInputsModal(true);
  };

  const handleClickModal = useCallback(async () => {
    await fetchData(customDates.from, customDates.to);
    setShowCustomInputsModal(false);
  }, [customDates]);

  return (
    <div className="w-4/5">
      <div className="flex flex-col gap-x-4">
        <div className="flex gap-x-4">
          <p className="underline">Time Scale:</p>
          <Dropdown<TIME_SCALE>
            options={options}
            defaultValue={selectedTimeScale}
            onChange={handleTimescaleChange}
          />
          {showButton && (
            <button
              onClick={handleClickButton}
              className="bg-blue-100-electripure text-white px-3 rounded-lg outline-none">
              Custom input
            </button>
          )}
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr>
                <td className="border-none"></td>
                {mdps.map((mdp, index) => (
                  <th
                    key={`${mdp.name}_col-${index}`}
                    scope="col"
                    className="font-bold w-40 max-w-40 px-2">
                    {mdp.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.entries(tableData).map(([key, values], index) => (
                <tr
                  key={`row-${index}`}
                  className="odd:bg-gray-100-electripure">
                  <td className="border-none font-semibold">{key}</td>
                  {(values as any).map((value, innerIndex) => (
                    <td
                      className="border-none text-center"
                      key={`row-${index}_col-${innerIndex}`}>
                      {index === 0 ? (
                        <div
                          className="w-4 h-4 rounded-full mx-auto"
                          style={{ backgroundColor: value }}
                        />
                      ) : (
                        value
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal
          open={showCustomInputsModal}
          onClose={() => setShowCustomInputsModal(false)}>
          <div className=" outline-none absolute top-1/2 left-1/2 -translate-x-1/2 w-80 -translate-y-1/2 bg-white p-12 flex flex-col gap-y-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <div
                className="absolute top-3 right-3 cursor-pointer hover:bg-black hover:bg-opacity-20 rounded-full px-1 py-1"
                onClick={() => setShowCustomInputsModal(false)}>
                <IconCross />
              </div>
              <div>
                <p>From</p>
                <DateTime
                  onChange={handleDateTimeChange}
                  value={customDates.from}
                  name="from"
                />
              </div>
              <div>
                <p>To</p>
                <DateTime
                  onChange={handleDateTimeChange}
                  value={customDates.to}
                  name="to"
                />
              </div>
              <button
                onClick={handleClickModal}
                className="bg-blue-100-electripure text-white py-3 rounded-lg">
                Search
              </button>
            </LocalizationProvider>
          </div>
        </Modal>
      </div>
    </div>
  );
};
