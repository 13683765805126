import { Formik, Form } from 'formik';
import { EntityForm } from '../EntityForm';
import { UpdateSiteFormData } from '@/utils/types';
import { CreateSiteSchema, UpdateSiteSchema } from '@/schemas';
import { TextInput } from '@/common/Input/Text';
import { Dropdown } from '@/common/Input/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { ElectripureState } from '@/interfaces/reducers';
import { CompanyEntity } from '@/interfaces/entities';
import { useMemo, useState } from 'react';
import { Button } from '@/common/Button';
import env from '@/config/env';
import { rateSchedule, timezones } from '@/utils/constants';
import { FileInput } from '@/common/Input/File';
import { CameraIcon } from '@/assets/svg';
import { FileType } from '@/utils/enum';
import { toast } from 'react-toastify';
import { ObjectRequestMapper } from '@/mappers/request.mapper';
import ElectripureService from '@/service/electripure-service';
import { sendGetOverview } from '@/actions/electripure';

type UpdateSiteFormProps = {
  companyId?: number;
  siteId: number;
  initialValues: Record<string, any>;
  toggleModal: (key?: string) => void;
};

export const UpdateSiteForm = ({
  siteId,
  toggleModal,
  initialValues
}: UpdateSiteFormProps) => {
  const companies: CompanyEntity[] = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  );
  const dispatch = useDispatch();

  const [file, setFile] = useState<{
    site: {
      file?: File;
      dirty: boolean;
    };
    schematic: {
      file?: File;
      dirty: boolean;
    };
  }>({
    site: { dirty: false },
    schematic: { dirty: false }
  });

  const companyDropdownOptions = useMemo(
    () =>
      [
        {
          value: '',
          text: <p className="text-gray-200-electripure">Select a company</p>
        }
      ].concat(
        companies.map((company) => ({
          value: company.company_id.toString(),
          text: <p>{company.company_name}</p>
        }))
      ),
    [companies]
  );

  const statesDropdownOptions = [
    {
      value: '',
      text: <p className="text-gray-200-electripure">Select State</p>
    }
  ].concat(
    env.STATES.map((state) => ({
      value: state,
      text: <p>{state}</p>
    }))
  );

  const rateScheduleDropdownOptions = [
    { value: '', text: <p>Select Rate</p> }
  ].concat(
    rateSchedule.map((rate) => ({
      value: rate,
      text: <p>{rate}</p>
    }))
  );

  const timezoneDropdownOptions = [
    { value: '', text: <p>Select Time zone</p> }
  ].concat(
    timezones.map((zone) => ({
      value: zone.id,
      text: <p>{zone.id.split('_').join(' ')}</p>
    }))
  );

  const handleSubmit = async (values: UpdateSiteFormData) => {
    const { site: sitePhoto, schematic: schematicPhoto } = file;

    const objectMap = {
      companyId: 'company_id',
      siteName: 'name',
      address: 'address',
      secondaryAddress: 'address2',
      city: 'city',
      state: 'state',
      timeZone: 'timezone',
      utilityCompany: 'utility_company',
      zipCode: 'zip',
      rateSchedule: 'rate_schedule',
      sitePhoto: 'logo',
      schematicPhoto: 'schematic'
    };

    const body = ObjectRequestMapper.map<
      UpdateSiteFormData & {
        sitePhoto?: File;
        schematicPhoto?: File;
      }
    >(
      {
        ...values,
        sitePhoto: sitePhoto.file,
        schematicPhoto: schematicPhoto.file
      },
      objectMap
    );

    const formData = new FormData();

    Object.entries(body).map(([key, value]) => formData.append(key, value));

    try {
      const response = await ElectripureService.updateSiteV2(formData, siteId);

      console.log(response);

      toggleModal();

      toast('Site created successfully!', {
        type: 'success',
        position: 'bottom-right'
      });
    } catch (error: any) {
      console.error(error);
      toast(`Could not update site: ${error.message}`, {
        type: 'error',
        position: 'bottom-right'
      });
    }
  };

  const handleFileChange = (
    file: File,
    name: string,
    isValidFileType?: boolean
  ) => {
    if (!isValidFileType)
      toast('Image type not supported', {
        type: 'error',
        position: 'bottom-right'
      });
    else {
      setFile((prev) => ({
        ...prev,
        [name]: { dirty: true, file, error: undefined }
      }));
    }
  };

  return (
    <div className="bg-white z-30 absolute top-1/2 left-1/2 -translate-x-1/2 max-h-screen -translate-y-1/2 p-8 overflow-y-auto">
      <EntityForm title="Let's get some site details">
        <Formik<UpdateSiteFormData>
          initialValues={{
            address: initialValues.address,
            secondaryAddress: initialValues.address2,
            city: initialValues.city,
            siteName: initialValues.name,
            state: initialValues.state,
            timeZone: initialValues.timezone, // Need something here
            zipCode: initialValues.zip,
            rateSchedule: initialValues.rate_schedule, // Need something here
            utilityCompany: initialValues.utility_company // Need something here
          }}
          validationSchema={UpdateSiteSchema}
          onSubmit={(values) => handleSubmit(values)}>
          {({ isSubmitting }) => (
            <Form className="flex flex-col grow gap-y-2">
              <TextInput
                label="Site name"
                name="siteName"
                placeholder="Site name"
              />
              <TextInput
                label="Address"
                name="address"
                placeholder="12345 Street Address"
              />
              <TextInput
                label={
                  <>
                    Address 2 <span className="italic">optional</span>
                  </>
                }
                name="secondaryAddress"
                placeholder="12345 Street Address"
              />
              <div className="flex gap-x-8">
                <TextInput label="City" name="city" placeholder="City" />
                <Dropdown
                  label="State"
                  name="state"
                  options={statesDropdownOptions}
                  defaultValue={statesDropdownOptions[0].value}
                />
                <TextInput label="Zipcode" name="zipCode" placeholder="Zip" />
              </div>

              <div className="flex gap-x-8">
                <div className="w-1/3">
                  <Dropdown
                    name="timeZone"
                    label="Time zone"
                    defaultValue={timezoneDropdownOptions[0].value}
                    options={timezoneDropdownOptions}
                  />
                </div>
                <Dropdown
                  name="rateSchedule"
                  label={
                    <>
                      Rate Schedule <span className="italic">optional</span>
                    </>
                  }
                  options={rateScheduleDropdownOptions}
                  defaultValue={rateScheduleDropdownOptions[0].value}
                />
                <TextInput
                  label={
                    <>
                      Utility Company <span className="italic">optional</span>
                    </>
                  }
                  name="utilityCompany"
                  placeholder="Utility Company"
                />
              </div>

              <div className="flex gap-x-6 mt-6 w-full h-64">
                <FileInput
                  name="site"
                  onChange={handleFileChange}
                  validFileTypes={[FileType.JPEG, FileType.PNG, FileType.SVG]}
                  className="w-full">
                  {({ openFileDialog }) => (
                    <>
                      <div className="bg-gray-200-electripure relative flex items-center justify-center h-full">
                        <div className="flex flex-col gap-y-4">
                          <p className="text-white font-bold text-center">
                            {file.site.file?.name
                              ? file.site.file.name
                              : 'Site Photo'}
                            <p className="italic">optional</p>
                          </p>
                          <p className="text-center text-white">
                            Acceptable formats <br />
                            PNG, JPEG, SVG
                          </p>
                        </div>
                        <div
                          className="absolute bottom-0 right-0 flex flex-col items-center bg-black p-2 cursor-pointer"
                          onClick={openFileDialog}>
                          <CameraIcon />
                          <span className="text-sm font-semibold text-white">
                            Add
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </FileInput>
                <FileInput
                  name="schematic"
                  onChange={handleFileChange}
                  validFileTypes={[FileType.PNG, FileType.JPEG, FileType.PDF]}
                  className="w-full">
                  {({ openFileDialog }) => (
                    <>
                      <div className="bg-gray-200-electripure relative flex items-center justify-center h-full">
                        <div className="flex flex-col gap-y-4">
                          <p className="text-white font-bold text-center">
                            {file.schematic.file?.name
                              ? file.schematic.file.name
                              : 'Site Schematic'}
                            <p className="italic">optional</p>
                          </p>
                          <p className="text-center text-white">
                            Acceptable formats <br />
                            PNG, JPEG, PDF
                          </p>
                        </div>
                        <div
                          className="absolute bottom-0 right-0 flex flex-col items-center bg-black p-2 cursor-pointer"
                          onClick={openFileDialog}>
                          <CameraIcon />
                          <span className="text-sm font-semibold text-white">
                            Add
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </FileInput>
              </div>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="text-sm mt-4 mx-auto flex jutify-center disabled:bg-blue-50-electripure bg-blue-100-electripure text-white h-10 w-40 rounded-md items-center outline-none">
                <p className="text-center w-full">Add</p>
              </Button>
            </Form>
          )}
        </Formik>
      </EntityForm>
    </div>
  );
};
