import { ErrorMessage, useField } from 'formik';
import { ChangeEvent, ReactNode } from 'react';

type DropdownProps = {
  name: string;
  options: {
    value: any;
    text: ReactNode;
  }[];
  label: ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  defaultValue: any;
  handleChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
};
export const Dropdown = ({
  name,
  options,
  label,
  defaultValue,
  disabled,
  multiple,
  handleChange
}: DropdownProps) => {
  const [field, meta] = useField(name);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    field.onChange(e);
    if (handleChange) handleChange(e);
  };
  return (
    <div className="flex flex-col gap-y-1 grow">
      <label className="w-full font-medium" htmlFor={field.name}>
        {label}{' '}
        {meta.error && (
          <span className="text-xs text-red-500">{meta.error}</span>
        )}
      </label>
      <select
        multiple={multiple}
        disabled={disabled}
        className={`px-4 py-2.5 border-[1px] border-gray-200-electripure outline-none ${
          field.value === options[0].value
            ? 'text-gray-300-electripure'
            : 'text-black'
        }`}
        {...field}
        onChange={onChange}
        defaultValue={defaultValue}>
        {options.map(({ value, text }, index) => (
          <option
            className={`${
              index === 0 ? 'text-gray-200-electripure' : 'text-black'
            } `}
            value={value}
            disabled={index === 0}
            key={index}>
            {text}
          </option>
        ))}
      </select>
    </div>
  );
};
