import { ReactNode } from 'react';

type DataTableProps = {
  columns: string[];
  data: ReactNode[][];
};

export const DataTable = ({ columns, data }: DataTableProps) => {
  return (
    <table className="w-full">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th className={`font-semibold text-left`} key={index}>
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} className="odd:bg-gray-100-electripure">
            {row.map((d, cellIndex) => (
              <td
                className={`border-none ${cellIndex === 0 && 'font-semibold'}`}
                key={`row-${rowIndex}.cell-${cellIndex}`}>
                {d}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
