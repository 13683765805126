import { Fragment } from 'react';
import { INPUT_CONTROL_STATE } from '../../../config/enum';
import InputCheckbox from '../../FormInput/InputCheckbox';
import Space from '../../Space';
import {
  ChannelOptionDictPowerLog,
  ChannelOptionPowerLog
} from '../../../interfaces/graph';

function ChannelControl({
  channelOptionDict,
  onChange
}: {
  channelOptionDict: ChannelOptionDictPowerLog;
  onChange: (channelOption: ChannelOptionPowerLog, checked: boolean) => void;
}) {
  const totalChannelShowed: number = Object.values(channelOptionDict).filter(
    (channelOption: ChannelOptionPowerLog) => channelOption.isShow
  ).length;
  const preventLastChecked: boolean = totalChannelShowed <= 1;
  return (
    <div className="flex justify-center items-center">
      {Object.values(channelOptionDict).map(
        (channelOption: ChannelOptionPowerLog, index: number) => {
          return (
            <Fragment key={index}>
              <InputCheckbox
                state={INPUT_CONTROL_STATE.DEFAULT}
                message={''}
                disabled={preventLastChecked && channelOption.isShow}
                defaultChecked={channelOption.isShow}
                classes={`f-semibold`}
                name={channelOption.key}
                label={channelOption.label}
                onChange={(checked: boolean) => {
                  onChange(channelOption, checked);
                }}
              />
              <Space classes="w-[10px]" />
            </Fragment>
          );
        }
      )}
    </div>
  );
}

export default ChannelControl;
