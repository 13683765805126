import { useField, useFormikContext } from 'formik';
import Select, { GroupBase, Options, OptionsOrGroups } from 'react-select';
import { CustomMenuList } from './CustomMenuList';

export type Option = {
  label: string;
  value: number;
};

type DropdownMultipleProps = {
  name: string;
  label: string;
  options: OptionsOrGroups<Option, GroupBase<Option>>;
  customMenuList?: boolean;
  onChange?: (selectedOptions: any) => void;
};

export const DropdownMultiple = ({
  name,
  options,
  label,
  onChange,
  customMenuList
}: DropdownMultipleProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (selectedOptions) => {
    setFieldValue(
      name,
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
    if (onChange) onChange(selectedOptions);
  };

  const selectedOptions = Array.isArray(options)
    ? options.flatMap((optionOrGroup) =>
        'options' in optionOrGroup
          ? optionOrGroup.options.filter((option) =>
              field.value.includes(option.value)
            )
          : field.value.includes(optionOrGroup.value)
          ? [optionOrGroup]
          : []
      )
    : [];

  const handleAddAll = () => {
    const allOptionValues = Array.isArray(options)
      ? options
          .flatMap((optionOrGroup) =>
            'options' in optionOrGroup ? optionOrGroup.options : [optionOrGroup]
          )
          .map((option) => option.value)
      : [];

    setFieldValue(name, allOptionValues);
  };

  const selectCustomComponents = customMenuList && {
    MenuList: (props) => (
      <CustomMenuList {...props} handleAddAll={handleAddAll} />
    )
  };

  return (
    <div className="flex flex-col gap-y-1">
      <label className="w-full font-medium">
        {label}{' '}
        {meta.error && (
          <span className="text-xs text-red-500">{meta.error}</span>
        )}
      </label>

      <Select
        className="rounded-none border-0"
        {...field}
        options={options}
        closeMenuOnSelect={false}
        isMulti={true}
        value={selectedOptions}
        components={{ ...selectCustomComponents }}
        onChange={handleChange}
        styles={{
          multiValueLabel: (base) => ({
            ...base,
            maxWidth: '90%'
          }),
          multiValue: (base) => ({
            ...base,
            maxWidth: '100px'
          }),
          control: (base) => ({
            ...base,
            maxWidth: '100%',
            width: '100%'
          }),
          menu: (base) => ({
            ...base,
            width: '100%',
            maxWidth: '100%'
          })
        }}
      />
    </div>
  );
};
