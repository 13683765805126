import { Fragment } from 'react';
import { INPUT_CONTROL_STATE } from '../../../config/enum';
import InputCheckbox from '../../FormInput/InputCheckbox';
import Space from '../../Space';
import {
  GroupOptionPowerLog,
  LabelOptionPowerLog
} from '../../../interfaces/graph';

function GroupControl({
  groupOption,
  onChange
}: {
  groupOption: GroupOptionPowerLog;
  onChange: (labelOption: LabelOptionPowerLog, checked: boolean) => void;
}) {
  const totalChannelShowed: number = Object.values(groupOption.labels).filter(
    (labelOption: LabelOptionPowerLog) => labelOption.isShow
  ).length;
  const preventLastChecked: boolean = totalChannelShowed <= 1;
  return (
    <div className="flex justify-center items-center">
      {Object.values(groupOption.labels).map(
        (labelOption: LabelOptionPowerLog, index: number) => {
          return (
            <Fragment key={index}>
              <InputCheckbox
                state={INPUT_CONTROL_STATE.DEFAULT}
                message={''}
                disabled={preventLastChecked && labelOption.isShow}
                defaultChecked={labelOption.isShow}
                classes={`f-semibold`}
                name={labelOption.name}
                label={labelOption.name}
                onChange={(checked: boolean) => {
                  onChange(labelOption, checked);
                }}
              />
              <Space classes="w-[10px]" />
            </Fragment>
          );
        }
      )}
    </div>
  );
}

export default GroupControl;
