import React, { ChangeEvent, LegacyRef, useEffect, useRef } from 'react';

type DropdownProps<T> = {
  options: Array<{ label: string; value: T }>;
  defaultValue: T;
  className?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

export const Dropdown = <T,>({
  options,
  defaultValue,
  className,
  onChange
}: DropdownProps<T>) => {
  return (
    <select
      defaultValue={defaultValue as string}
      className={`outline-none border-[2px] px-3 py-1 ${className}`}
      onChange={onChange}>
      {options.map((option, index) => (
        <option key={index} value={option.value as string}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
