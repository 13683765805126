import { INotification } from '../../interfaces/electripure-service';
import { IoAlertOutline } from 'react-icons/io5';
import { HiBellAlert } from 'react-icons/hi2';

export interface INotificationItemProps {
  notification: INotification;
  type: string;
}

function NotificationItem({ notification, type }: INotificationItemProps) {
  return (
    <div className="py-4 flex items-center justify-center">
      <div className="w-[30px] flex items-center justify-center">
        <div className="p-2">
          {type === 'alert' && <IoAlertOutline color="red" size={20} />}
          {type === 'event' && <HiBellAlert color="#ffc830" size={20} />}
        </div>
      </div>
      <div className="flex-1 pr-2">
        <div className="flex justify-between">
          <p className="text-sm font-bold">
            {notification.deviceName}
            {` (${notification.device})`}
          </p>
          <p className="text-sm">{notification.timeValue}</p>
        </div>
        <p className="text-sm flex flex-col">
          {notification.message}: {notification.chart}
        </p>
      </div>
    </div>
  );
}

export default NotificationItem;
