import { useState } from 'react';
import { ModalMiddle } from './../components/Modal';
import { CiaPermission } from '../routers/Permissions';
import { ButtonSecondary } from '../components/FormInput/Button';
import DataTableDevices from '../components/DataTables/DataTableDevices';
import { settingPermissions } from '../libs/permissions';
import { RoleGuard } from '@/guards/RoleGuard';
import { PERMISSIONS } from '@/utils/roles';
import { IconAdd } from '@/assets/svg';
import { Modal } from '@mui/material';
import { CreateDeviceForm } from '@/common/Form/CreateDevice';
import { useDispatch } from 'react-redux';
import { sendGetDevicesTable } from '@/actions/electripure';

function DeviceListPage() {
  const [isShowModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <div className="px-[30px] py-[10px] w-full">
        <div className="justify-center items-center flex mb-[2 0px] sm:justify-start flex-col-reverse sm:flex-row mb-4">
          <div className="w-[200px]">
            <RoleGuard allowedPermissions={[PERMISSIONS.CREATE_MDP]}>
              <ButtonSecondary onClick={() => setShowModal(true)}>
                <span className="flex justify-center items-center">
                  <IconAdd />
                  <span className="f-medium">Add new device</span>
                </span>
              </ButtonSecondary>
            </RoleGuard>
          </div>
          <span className="ml-[20px]">
            <h3 className="f-bold text-lg">Device Management</h3>
          </span>
        </div>
        <RoleGuard allowedPermissions={[PERMISSIONS.VIEW_MDP]}>
          <div className="w-full rounded border-color-secondary border">
            <DataTableDevices />
          </div>
        </RoleGuard>
      </div>
      <Modal
        open={isShowModal}
        onClose={() => {
          dispatch(sendGetDevicesTable({}));
          setShowModal(false);
        }}>
        <CreateDeviceForm />
      </Modal>
    </>
  );
}

export default DeviceListPage;
