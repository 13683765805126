import { TYPE_EVENTS_EVENT } from '../../../config/enum';
import InputCheckboxIcon from '../../FormInput/InputCheckboxIcon';
import painIcon from './../../../assets/img/pain.png';
function EventMenu({
  tooltip,
  legend,
  refresh,
  onChange
}: {
  tooltip: boolean;
  legend: boolean;
  refresh: boolean;
  onChange: (type: TYPE_EVENTS_EVENT, checked: boolean) => any;
}) {
  return (
    <div className="flex justify-center items-center">
      <InputCheckboxIcon
        disabled={false}
        defaultChecked={tooltip}
        classes={`f-semibold rounded-l-md`}
        name={'tooltip'}
        tooltip="Tooltips"
        onChange={(checked: boolean) => {
          onChange(TYPE_EVENTS_EVENT.TOOLTIP, checked);
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={`w-8 h-8 ${tooltip ? 'fill-blue-500' : ''}`}>
          <path
            fillRule="evenodd"
            d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z"
            clipRule="evenodd"
          />
        </svg>
      </InputCheckboxIcon>
      <InputCheckboxIcon
        disabled={false}
        defaultChecked={legend}
        classes={`f-semibold`}
        name={'legends'}
        tooltip="Legends"
        onChange={(checked: boolean) => {
          onChange(TYPE_EVENTS_EVENT.LEGENDS, checked);
        }}>
        <div className="w-8 h-8 p-1">
          <img src={painIcon} className="w-full h-full rounded-sm" />
        </div>
      </InputCheckboxIcon>
      <InputCheckboxIcon
        disabled={false}
        defaultChecked={refresh}
        classes={`f-semibold rounded-r-md`}
        name={'refresh'}
        tooltip="Refresh"
        onChange={(checked: boolean) => {
          onChange(TYPE_EVENTS_EVENT.REFRESH, checked);
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-8 h-8 ${refresh ? 'stroke-blue-500' : ''}`}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
      </InputCheckboxIcon>
    </div>
  );
}

export default EventMenu;
