export enum PERMISSIONS {
  EDIT_COMPANY = 'edit_company',
  CREATE_COMPANY = 'create_company',
  VIEW_COMPANY = 'view_company',
  DELETE_COMPANY = 'delete_company',
  VIEW_SITE = 'view_site',
  CREATE_SITE = 'create_site',
  EDIT_SITE = 'edit_site',
  DELETE_SITE = 'delete_site',
  VIEW_MDP = 'view_mdp',
  CREATE_MDP = 'create_mdp',
  EDIT_MDP = 'edit_mdp',
  DELETE_MDP = 'delete_mdp',
  VIEW_USER = 'view_user',
  CREATE_USER = 'create_user',
  EDIT_USER = 'edit_user',
  DELETE_USER = 'delete_user'
}

export const ROLES = { 
  CUSTOMER_ADMIN: 'customer_admin',
  CUSTOMER_SITE_MANAGER: 'customer_site_manager',
  CUSTOMER_USER: 'customer_user',
  ELECTRIPURE_ADMIN: 'electripure_admin',
  ELECTRIPURE_EMPLOYEE: 'electripure_employee',
  ELECTRIPURE_ENGINEER: 'electripure_engineer',
  ELECTRIPURE_MANAGER: 'electripure_manager'
} as const;
