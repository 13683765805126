import { useParams } from 'react-router';
import {
  YConfig,
  ChannelConfig,
  ChannelDataPowerLog,
  AxisConfigDict,
  PowerLogConfig
} from '@/interfaces/graph';
import PowerLogGraph from '../PowerLogGraph';
import environment from '@/config/env';

const graphMetadata: ChannelConfig[] = [
  {
    key: 'a',
    label: 'A',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_A_v2`
  },
  {
    key: 'b',
    label: 'B',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_B_v2`
  },
  {
    key: 'c',
    label: 'C',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_C_v2`
  },
  {
    key: 't',
    label: 'T',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_T_v2`
  }
];
function toData(channel: ChannelConfig, result: any): ChannelDataPowerLog {
  return {
    x: result['TS_data'],
    x_label: result['TS_data_label'],
    timestamp: result['TS_unix'],
    y: {
      // dpf_average: result['DPF_AVG'],
      // dpf_max: result['DPF_MAX'],
      // dpf_min: result['DPF_MIN'],

      pf_average: result['PF_AVG'],
      // pf_max: result['PF_MAX'],
      // pf_min: result['PF_MIN'],

      var_average: result['VAR_AVG'],
      // var_max: result['VAR_MAX'],
      // var_min: result['VAR_MIN'],

      va_average: result['VA_AVG'],
      // va_max: result['VA_MAX'],
      // va_min: result['VA_MIN'],

      w_average: result['W_AVG']
      // w_max: result['W_MAX'],
      // w_min: result['W_MIN']
    },
    backgroundRanges: [],
    y_type_point: {
      // dpf_average: result['DPF_AVG'].map(() => 'Normal'),
      // dpf_max: result['DPF_MAX'].map(() => 'Normal'),
      // dpf_min: result['DPF_MIN'].map(() => 'Normal'),
      pf_average: result['PF_AVG'].map(() => 'Normal'),
      // pf_max: result['PF_MAX'].map(() => 'Normal'),
      // pf_min: result['PF_MIN'].map(() => 'Normal'),
      var_average: result['VAR_AVG'].map(() => 'Normal'),
      // var_max: result['VAR_MAX'].map(() => 'Normal'),
      // var_min: result['VAR_MIN'].map(() => 'Normal'),
      va_average: result['VA_AVG'].map(() => 'Normal'),
      // va_max: result['VA_MAX'].map(() => 'Normal'),
      // va_min: result['VA_MIN'].map(() => 'Normal'),
      w_average: result['W_AVG'].map(() => 'Normal')
      // w_max: result['W_MAX'].map(() => 'Normal'),
      // w_min: result['W_MIN'].map(() => 'Normal')
    }
  };
}
// !SECTION
// SECTION Mapper for config for each y dataset
// NOTE Power log graph will group all the declared groups and also the labels declared for each group (respecting the index of label and group). For a group to be displayed its labels must be active.
const yConfigs: YConfig[] = [
  {
    axisGroup: 'y2',
    key: 'pf_max',
    color: '#13007F',
    label: 'PF Maximum',
    labelShort: 'PF Max',
    labelList: ['PF', 'Maximum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y2',
    key: 'pf_average',
    color: '#387CFD',
    label: 'PF Average',
    labelShort: 'PF Average',
    labelList: ['PF', 'Average'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y2',
    key: 'pf_min',
    color: '#8FFEFE',
    label: 'PF Minimum',
    labelShort: 'PF Min',
    labelList: ['PF', 'Minimum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'var_max',
    color: '#C8140D',
    label: 'VAR Maximum',
    labelShort: 'kVAR Max',
    labelList: ['VAR', 'Maximum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'var_average',
    color: '#F6221B',
    label: 'VAR Average',
    labelShort: 'kVAR Average',
    labelList: ['VAR', 'Average'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'var_min',
    color: '#F78282',
    label: 'VAR Minimum',
    labelShort: 'kVAR Min',
    labelList: ['VAR', 'Minimum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'va_max',
    color: '#7C4106',
    label: 'VA Maximum',
    labelShort: 'kVA Max',
    labelList: ['VA', 'Maximum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'va_average',
    color: '#F78216',
    label: 'VA Average',
    labelShort: 'kVA Average',
    labelList: ['VA', 'Average'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'va_min',
    color: '#FCFF23',
    label: 'VA Minimum',
    labelShort: 'kVA Min',
    labelList: ['VA', 'Minimum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'w_max',
    color: '#239D0C',
    label: 'W Maximum',
    labelShort: 'kW Max',
    labelList: ['W', 'Maximum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'w_average',
    color: '#3FFD1E',
    label: 'W Average',
    labelShort: 'kW Average',
    labelList: ['W', 'Average'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  },
  {
    axisGroup: 'y',
    key: 'w_min',
    color: '#8BFF83',
    label: 'W Minimum',
    labelShort: 'kW Min',
    labelList: ['W', 'Minimum'],
    // groupList: ['Power Units', 'Display'],
    groupList: ['Power Units']
  }
];
const axisConfigDict: AxisConfigDict = {
  y: {
    position: 'left',
    type: 'linear'
  },
  y2: {
    position: 'right',
    type: 'linear',
    max: 1,
    min: 0
  }
};

const powerLogConfig: PowerLogConfig = {
  threshold: 0
};

function PowerGraph({ defaultMeterId }: { defaultMeterId?: number }) {
  let { meterId } = useParams();
  let deviceId = defaultMeterId ?? parseInt(meterId!);

  return (
    <PowerLogGraph
      config={powerLogConfig}
      yConfigList={yConfigs}
      toData={toData}
      deviceId={deviceId}
      axisConfigDict={axisConfigDict}
      channelConfigList={graphMetadata}
    />
  );
}

export default PowerGraph;
