import { MenuListProps, components } from 'react-select';
import { Option } from '..';
import { MouseEvent } from 'react';

interface CustomMenuListProps extends MenuListProps<Option, true> {
  handleAddAll: () => void;
}

export const CustomMenuList = ({
  handleAddAll,
  ...props
}: CustomMenuListProps) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    // e.stopPropagation();
    handleAddAll();
  };

  console.log('Options: ', props.options);
  return (
    <>
      {props.options.length > 0 && (
        <div onClick={handleClick} className="p-2 cursor-pointer">
          Select All
        </div>
      )}
      <components.MenuList {...props} />
    </>
  );
};
