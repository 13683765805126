import { CustomChartData } from '@/types';
import { ChartData } from 'chart.js';

type ChartLegend = {
  legends: {
    backgroundColor: string;
    label: string;
  }[];
};

export const ChartLegend = ({ legends }: ChartLegend) => {
  return (
    <div className="w-full flex gap-x-4">
      {legends.map((l, index) => (
        <div key={index} className="flex items-center gap-x-2">
          <span
            className="h-1 w-6 block"
            style={{ background: l.backgroundColor }}
          />
          {l.label}
        </div>
      ))}
    </div>
  );
};
