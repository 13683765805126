import Navegation from '../components/Navigation';
import { Outlet } from 'react-router-dom';
import { NAVBAR_MODE } from '../config/enum';

export interface IDashboardPageProps {
  mode: NAVBAR_MODE;
}

function DashboardPage({ mode }: IDashboardPageProps) {
  return (
    <>
      <div className="h-screen w-screen">
        <Navegation mode={mode}>
          <div className="px-[0px] md:px-[30px] pb-[10px] w-full h-full">
            <Outlet />
          </div>
        </Navegation>
      </div>
    </>
  );
}

export default DashboardPage;
