import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ElectripureState } from '../interfaces/reducers';
import { setSession } from '../actions/electripure';

type AuthProviderProps = {
  children: React.ReactNode;
};
export const AuthContext = createContext<any>({});

export default ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch();
  const jwt =
    useSelector((state: ElectripureState) => state.electripureJwt) ||
    localStorage.getItem('electripureJwt');

  useEffect(() => {
    if (jwt) dispatch(setSession({ jwt }));
  }, []);
  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};
