import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ElectripureState } from './../../../interfaces/reducers';
import { TYPE_SPACE } from '../../../config/enum';
import { ButtonNotification } from '../../FormInput/Button';
import {
  setJwt,
  setLoginToken,
  setTimestampTwoStepVerification
} from './../../../actions/electripure';
import InputSearch from '../../FormInput/InputSearch';
import Space from '../../Space';

import FloarUserSetting from './components/FloarUserSetting';
import FloatUserSetting from './components/FloarUserSetting';
import barLogo from './../../../assets/img/bar.png';
import FloatNotifications from './components/FloatNotifications';

function NavigationTop() {
  const user = localStorage.getItem('current_user');
  let name;
  let surname;
  if (user) {
    name = user.split(' ')[0];
    surname = user.split(' ')[1];
  } else {
    name = ' ';
    surname = ' ';
  }

  return (
    <div className="w-full md:h-[60px] flex justify-end items-start md:items-center px-[30px] md:flex-nowrap flex-wrap flex-col-reverse md:flex-row">
      <div className="w-full sm:w-fit  h-full flex justify-center items-center sm:flex-nowrap flex-wrap flex-col-reverse sm:flex-row">
        <div className="flex justify-center items-center flex-nowrap gap-x-4">
          <span className="flex justify-center items-center flex-nowrap">
            <span className="f-semibold color-black-dark"> {name}</span>
          </span>
          <div className="flex">
            <FloatNotifications />
            <Space type={TYPE_SPACE.TEXT_DISTANCE_VERTICAL} />
            <FloatUserSetting>
              <img src={barLogo} className="w-[20px]" />
            </FloatUserSetting>
          </div>
        </div>
      </div>
      <Space classes="h-[10px] w-full md:hidden" />
    </div>
  );
}

export default NavigationTop;
