import { useEffect, useState } from 'react';
import { ElectripureState } from '../interfaces/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { sendGetUsers } from './../actions/electripure';
import { ModalMiddle } from '../components/Modal';
import { UpdateUserDataForm } from '../interfaces/form';
import UserUpdateForm from '../components/Form/UserUpdateForm';
import { sendUpdateUserDetails } from '../actions/electripure';
import { RoleGuard } from '@/guards/RoleGuard';
import { PERMISSIONS } from '@/utils/roles';
import { IconContactCard, IconTrash, IconUserRole } from '@/assets/svg';

const UserDetails = () => {
  const dispatch = useDispatch();

  let currentUser: any = JSON.parse(
    useSelector((state: ElectripureState) => state.currentUser) ?? '{}'
  );

  const [toggleModal, setToggleModal] = useState(false);

  const users = JSON.parse(
    useSelector((state: ElectripureState) => state.users)
  );

  useEffect(() => {
    dispatch(sendGetUsers({}));
  }, []);

  const user: any = users.find((element: any) => element.id == currentUser.id);

  const firstName = user?.Name?.split(' ')[0];
  const lastName = user?.Name?.split(' ')[1];

  const submitUserUpdateInfo = (data: UpdateUserDataForm) => {
    dispatch(sendUpdateUserDetails(data));
    setToggleModal(false);
  };

  const formatUserRole = (role: string) =>
    role
      ?.split('_')
      ?.map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      ?.join(' ');

  return (
    <>
      <div className="flex bg-white p-[20px] h-[800px] flex-col">
        <div className="flex">
          <div className="rounded-[50%] border w-[100px] h-[100px] flex justify-around items-center font-bold text-4xl">
            <h1>
              {firstName && firstName[0].toUpperCase()}
              {lastName && lastName[0].toUpperCase()}
            </h1>
          </div>
          <div className="ml-[100px]">
            <div className="flex">
              <h1 className="text-3xl">{user?.Name}</h1>
              <RoleGuard allowedPermissions={[PERMISSIONS.EDIT_USER]}>
                <span
                  className="cursor-pointer mt-[20px] ml-[20px] text-[#00AEE8]"
                  onClick={() => setToggleModal(!toggleModal)}>
                  Edit User Information
                </span>
              </RoleGuard>
            </div>
            <ModalMiddle
              show={toggleModal}
              onClose={() => {
                setToggleModal(false);
              }}>
              <UserUpdateForm onSubmit={submitUserUpdateInfo} />
            </ModalMiddle>
            <div className="mt-[10px]">
              <p className="flex items-center">
                <IconContactCard />
                {user?.Company}
              </p>
            </div>
            <div className="mt-[10px]">
              <p>Status: {user?.Status}</p>
            </div>
            <div className="mt-[10px]">
              <p>Email: {user?.email}</p>
            </div>
            <div className="mt-[10px]">
              <p>Cellphone: {user?.cellphone}</p>
            </div>
            <div className="mt-[10px]">
              <p>Account created on: {user?.date}</p>
            </div>
            <div className="mt-[10px]">
              <p className="flex items-center">
                <IconUserRole />
                {formatUserRole(user?.Role)}
              </p>
            </div>
            <div className="mt-[10px]">
              <p>Number of contacts: {user?.contact_backup.length}</p>
            </div>
          </div>
        </div>
        <div className="flex w-[100%] mt-[25px] text-3xl items-center justify-evenly">
          <h1>Back up contact</h1>
          <hr className="w-[75%]" />
        </div>
        <div className="mt-[25px] flex flex-wrap justify-around overflow-scroll">
          {user?.contact_backup?.map((contact: any) => (
            <div
              key={contact.contact_backup_id}
              className="flex w-[600px] h-[220px] border m-[20px] justify-around">
              <div className="flex w-[90%] p-[20px] flex-col">
                <div className="flex items-center">
                  <div className="rounded-[50%] border w-[80px] h-[80px] flex justify-around items-center font-bold text-2xl">
                    <h1>
                      {contact?.contact_backup_name[0].toUpperCase()}
                      {contact?.contact_backup_name[1].toUpperCase()}
                    </h1>
                  </div>
                  <p className="text-3xl ml-[50px]">
                    {contact.contact_backup_name}
                  </p>
                </div>
                <div className="ml-[120px] flex items-center">
                  <div>
                    <IconContactCard />
                  </div>
                  <div className="ml-[10px]">
                    <p>{contact.contact_backup_phone}</p>
                    <p>{contact.contact_backup_email}</p>
                  </div>
                </div>
              </div>
              <div className="w-[10%]">
                <IconTrash />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UserDetails;
