import { Fragment, useEffect, useState } from 'react';
import { ButtonNotification } from '../../../FormInput/Button';
import useOnclickOutside from 'react-cool-onclickoutside';
import UserSettings from './UserSettings';
import { useDispatch, useSelector } from 'react-redux';
import { ElectripureState } from '../../../../interfaces/reducers';
import {
  getNotifications,
  setNotifications,
  showToast
} from '../../../../actions/electripure';
import NotificationList from '../../../Notification/NotificationList';
import socketIOClient from 'socket.io-client';
import { INotification } from '../../../../interfaces/electripure-service';
import { AnyAaaaRecord } from 'dns';
import { CompanyEntity } from '../../../../interfaces/entities';

interface Message {
  id: string;
  text: string;
}

function FloatNotifications() {
  const [newMessage, setNewMessage] = useState<string>('');
  const [socket, setSocket] = useState<any>(null);
  const companies: CompanyEntity[] = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  );
  const dispatch = useDispatch();

  // const [notifications, setNotifications] = useState<INotification[]>([]);
  // useEffect(() => {
  //   const socket = socketIOClient('wss://apiprd.electripuremeter.com'); // Replace with your Socket.IO server URL
  //   setSocket(socket);
  //   socket.on('message', (message: string) => {
  //     // Explicitly type the message parameter as INotification
  //     // console.log(message);
  //     var alert = JSON.parse(message);
  //     //console.log('alert', alert);
  //     setNotifications((prev) => [
  //       ...prev,
  //       {
  //         chart: alert.event_value,
  //         companyId: '',
  //         companyName: alert.event_type,
  //         device: alert.device_id,
  //         deviceName: alert.device_name,
  //         message: alert.event_description,
  //         siteId: '',
  //         siteName: '',
  //         timeValue: alert.event_time,
  //         eventTimeValue: '',
  //         dateValue: ''
  //       }
  //     ]); // Update setNotifications to correctly handle the new message
  //   });
  //   socket.on('alert', (message: string) => {
  //     // Explicitly type the message parameter as INotification
  //     // console.log(message);
  //     var alert = JSON.parse(message);
  //     // console.log('alert', alert);
  //     setAlerts((prev) => [
  //       ...prev,
  //       {
  //         chart: alert.event_value,
  //         companyId: '',
  //         companyName: alert.event_type,
  //         device: alert.device_id,
  //         deviceName: alert.device_name,
  //         message: alert.event_description,
  //         siteId: '',
  //         siteName: '',
  //         timeValue: alert.event_time,
  //         eventTimeValue: '',
  //         dateValue: ''
  //       }
  //     ]); // Update setNotifications to correctly handle the new message
  //   });
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  const handleDeviceSubscription = (meter_id: number) => {
    if (socket) {
      socket.emit('subscribe', { meter_id: meter_id });
      console.log('Subscribed to ' + meter_id);
    }
  };

  const handleDeviceUnsubscription = (meter_id: number) => {
    if (socket) {
      socket.emit('unsubscribe', { meter_id: meter_id });
      console.log('Unsubscribe to ' + meter_id);
    }
  };

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [alerts, setAlerts] = useState<INotification[]>([]);
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = (): void => setShowMenu(true);
  const closeMenu = (): void => setShowMenu(false);

  const ref = useOnclickOutside(() => closeMenu());
  const [activeTab, setActiveTab] = useState('Events');

  return (
    <Fragment>
      <div ref={ref}>
        <ButtonNotification
          notifications={notifications.length}
          onClick={showMenu ? closeMenu : openMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6">
            <path
              fillRule="evenodd"
              d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
              clipRule="evenodd"
            />
          </svg>
        </ButtonNotification>

        {showMenu && (
          <div className="absolute shadow-md md:top-[110px] lg:top-[70px] bg-white w-[400px] max-h-[350px] overflow-y-auto py-[20px] px-[10px] right-[30px] rounded-lg z-10">
            <div className="w-full gap-1 flex">
              <div
                onClick={() => {
                  setActiveTab('Events');
                }}
                className="gap-4 w-1/2 flex items-center justify-center text-center cursor-pointer hover:bg-blue-200">
                Events{' '}
                <span className="p-2 bg-yellow-400 rounded-full">
                  {notifications.length}
                </span>
              </div>
              <div
                onClick={() => {
                  setActiveTab('Alerts');
                }}
                className="gap-4 w-1/2 flex items-center justify-center  text-center cursor-pointer hover:bg-blue-200">
                Alerts{' '}
                <span className="p-2 bg-red-400 rounded-full">
                  {alerts.length}
                </span>
              </div>

              <div
                onClick={() => {
                  setActiveTab('Companies');
                }}
                className="gap-4 w-1/2 flex items-center justify-center  text-center cursor-pointer hover:bg-blue-200">
                Config{' '}
              </div>
            </div>
            {activeTab === 'Events' ? (
              <NotificationList notifications={notifications} type={'event'} />
            ) : activeTab === 'Alerts' ? (
              <NotificationList notifications={alerts} type={'alert'} />
            ) : (
              <>
                {activeTab === 'Companies' ? (
                  <ul>
                    {companies.map((company) => (
                      <li
                        key={company.company_id}
                        className="border-gray-300 border-2 p-2 my-1">
                        {company.company_name}
                        <ul>
                          {company.list_sites.map((site) => (
                            <li key={site.id}>
                              {site.name}
                              <ul>
                                {site.mdp_list.map((device) => (
                                  <li
                                    key={device.id}
                                    className="cursor-pointer hover:bg-slate-300 border-gray-300 border-2 p-2">
                                    {device.name} - {device.meter_id}
                                    <input type="checkbox" name="" id="" />
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default FloatNotifications;
